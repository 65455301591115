@use "../config/" as *;
.waf-complete-profile {
    @include background(clr(primary-900), "pattern/login-bg.png", top/cover);
    .form-title {
        @extend %mb-2;
    }
    .form-area {
        background: linear-gradient(180deg, rgba(0, 10, 68, 0.64) 68.48%, rgba(0, 10, 68, 0) 100%);
        @extend %full-radius;
        @extend %py-6;
        @extend %flex-c-n;
    }
    .form-sub-title {
        flex-basis: 100%;
        @extend %mb-1;
    }
    .form-head {
        @extend %flex-wrap;
        @extend %flex-n-c;
    }
    .step-section {
        flex-basis: 50%;
    }
    .step-label {
        @extend %mt-1;
        @extend %font-12;
        @extend %primary-50;
        @extend %capitalize;
    }
    .skip {
        @extend %font-12-pr;
        @extend %pure-white-900;
        @extend %ml-auto;
    }
    .custom-form {
        @extend %flex-wrap;
        @extend %flex-sb-n;
    }
    .current-step {
        @extend %tertiary-700;
    }
    .btn-fill {
        @extend %btn-fill;
    }
    .dropdown {
        flex-direction: column-reverse;
    }
    .favourite-player {
        .title {
            border-bottom: .1rem solid clr(neutral-200);
            @extend %font-10-pb;
            @extend %mx-4;
            @extend %py-2;
            @extend %uppercase;
        }
        .list-item {
            @extend %relative;
            &:not(:last-child):after {
                content: "";
                height: .1rem;
                width: 88%;
                @extend %pos-x-center;
                @extend %d-block;
                @extend %neutral-200-bg;
            }
            button {
                padding: 1.3rem 0 1.3rem var(--space-4);
            }
        }
        .full-name {
            @extend %flex-column;
            @extend %ml-2;
        }
        .name {
            @extend %font-12-pb;
            @extend %capitalize;
        }
        .player-image {
            border: .1rem solid clr(tertiary-800);
            @include square(4.4rem);
            @extend %circle-radius;
            @extend %p-1;
            img {
                object-fit: contain;
                @include square(100%);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-complete-profile {
        padding: 0;
        @include background(clr(primary-900), "pattern/login-bg-web.png", top/cover);
        .form-area {
            width: 50%;
            margin-left: auto;
            padding: var(--space-3) var(--space-2);
            height: calc(var(--window-inner-height) - var(--header-height));
        }
        .form-wrapper {
            overflow-y: auto;
            height: 100%;
            @include custom-scroll();
        }
        .next-btn,
        .submit-btn {
            width: 35%;
        }
    }
}